export const THEME_DATA = {
  createTime: '2021-01-25 20:11:11.000',
  createAccountId: '1353676425401794562',
  updateTime: '2021-04-12 16:26:14.000',
  updateAccountId: '1353676425401794562',
  icontitleId: '1353676786111938561',
  icontitleName: '默认主题',
  icontitleCompany: 'lang_digital_transformation_platform',
  icontitlePlatCnname: 'lang_digital_transformation_platform',
  icontitlePlatEnname: '',
  icontitleBackground: '/river-fs/file/view?fileId=01251218475290002&state=public&clientId=xxxx',
  icontitleLeftColor: 35,
  icontitleCompanyLogo: '/river-fs/file/view?fileId=01251219187630003&state=public&clientId=111',
  icontitleLoginColor: 'rgb(38, 65, 112)',
  icontitleLoginButton: 'rgba(30, 88, 188, 1)',
  icontitleDoorTitle: 'lang_digital_transformation_platform',
  icontitleDoorLogo: '/river-fs/file/view?fileId=01251219187630003&state=public&clientId=111',
  icontitleIcon: '/river-fs/file/view?fileId=01251219187630003&state=public&clientId=111',
  statusFlag: 1,
  icontitleNamePinyin: 'mrdlzt',
  delFlag: 'N',
  icontitleCompanyColor: 'rgba(255, 255, 255, 1)',
  icontitlePlatCnnameColor: 'rgba(255, 255, 255, 1)',
  icontitlePlatEnnameColor: 'rgba(255, 255, 255, 1)',
  stripeColor: 'rgba(255, 255, 255, 1)',
  loginWayColor: 'rgba(38, 65, 112, 1)',
  icontitleDoorTitleColor: 'rgba(255, 255, 255, 1)',
  menuCollapseButtonColor: 'rgba(255, 255, 255, 1)'
}