var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-header", { staticClass: "header-wrap" }, [
    _c("div", { staticClass: "header-left" }, [_vm._t("left")], 2),
    _c("div", { staticClass: "header-right" }, [
      _vm.showCompany
        ? _c(
            "div",
            { staticClass: "header-info-box" },
            [
              _vm.companys.length !== 0
                ? _c(
                    "el-dropdown",
                    {
                      staticClass: "switch-dropdown",
                      attrs: { trigger: "click" },
                      on: { command: _vm.switchCompany },
                    },
                    [
                      _c("div", { staticClass: "topTag" }, [
                        _vm._v(
                          " " + _vm._s(_vm.loginCompany.companyName) + " "
                        ),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                          staticStyle: { "font-size": "14px" },
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.companys.length !== 0,
                              expression: "companys.length !== 0",
                            },
                          ],
                          staticStyle: { border: "0px" },
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.companys, function (item, index) {
                          return _c(
                            "el-dropdown-item",
                            { key: index, attrs: { command: item.companyId } },
                            [_vm._v(" " + _vm._s(item.companyName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.companys.length === 0
                ? _c("div", { staticClass: "topTag" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.loginCompany.companyName
                            ? _vm.loginCompany.companyName
                            : "无公司信息"
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.showCompany ? _c("div", [_vm._v("|")]) : _vm._e(),
      _vm.showDept
        ? _c(
            "div",
            { staticClass: "header-info-box" },
            [
              _vm.depts.length !== 0
                ? _c(
                    "el-dropdown",
                    {
                      staticClass: "switch-dropdown",
                      attrs: { trigger: "click" },
                      on: { command: _vm.switchDept },
                    },
                    [
                      _c("div", { staticClass: "topTag" }, [
                        _vm._v(" " + _vm._s(_vm.loginDept.deptName) + " "),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                          staticStyle: { "font-size": "14px" },
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.depts.length !== 0,
                              expression: "depts.length !== 0",
                            },
                          ],
                          staticStyle: { border: "0px" },
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.depts, function (item) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: item.deptId,
                              attrs: { command: item.deptId },
                            },
                            [_vm._v(" " + _vm._s(item.deptName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.depts.length === 0
                ? _c("div", { staticClass: "topTag" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.loginDept.deptName
                            ? _vm.loginDept.deptName
                            : "无部门信息"
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.showDept ? _c("div", [_vm._v("|")]) : _vm._e(),
      _c(
        "div",
        { staticClass: "header-info-box" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "switch-dropdown",
              attrs: { trigger: "click" },
              on: { command: _vm.updatePwd },
            },
            [
              _c("div", { staticClass: "topTag" }, [
                _vm._v(_vm._s(_vm.user.userFullNameCn)),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "pwd" } }, [
                    _vm._v("修改密码"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showLogout ? _c("div", [_vm._v("|")]) : _vm._e(),
      _vm.showLogout
        ? _c("div", { staticClass: "logout-icon" }, [
            _c("i", {
              staticClass: "el-icon-switch-button",
              on: { click: _vm.logout },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }