import HttpUtil from '@/utils/HttpUtil';

export default {

  //切换公司 切换部门
  switchCompanyDept(params) {
    let url = process.env.VUE_APP_SERVER_CODE + "switchCompanyDept";
    return HttpUtil.post(url, params);
  },
  //退出
  logout(params) {
    let url = process.env.VUE_APP_SERVER_CODE + "logout";
    return HttpUtil.get(url, params);
  },
  //修改密码
  password(params) {
    let url = process.env.VUE_APP_SERVER_CODE + "id/user/set/password";
    return HttpUtil.post(url, params);
  }
};
